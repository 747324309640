import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './store/rootReducer'
import { composeWithDevTools } from 'redux-devtools-extension'


const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
})

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
export default store

module.exports.AUTH_ROUTE='auth/local/'
module.exports.REGISTER_ROUTE='auth/local/register'
module.exports.GET_CUSTOMER_ROUTE='customers/'
module.exports.GET_CUSTOMER_ID_ROUTE='customers/:id'

module.exports.GET_PROVIDER_ROUTE='providers/'

module.exports.GET_EMPLOYEE_ROUTE='employees/'
module.exports.GET_EMPLOYEE_ID_ROUTE='employees/:id'

module.exports.GET_ORDERS_ROUTE='orders/'
module.exports.GET_ORDERS_ID_ROUTE='orders/:id'

module.exports.GET_ORDER_DETAIL_ROUTE='order-details/'

module.exports.GET_PROVIDERS_ROUTE='providers/'
module.exports.GET_PROVIDERS_ID_ROUTE='providers/:id'

module.exports.GET_PHIEUTHU_ROUTE='phieu-thus/'
module.exports.GET_PHIEUCHI_ROUTE='phieu-chis/'

module.exports.GET_CATEGORY_ROUTE='categories/'

module.exports.GET_HANGTRONGKHO_ROUTE='hang-trong-khos/'

module.exports.GET_BANKACCOUNT_ROUTE='bank-accounts/'

module.exports.CREATE_MULTI_HANGTRONGKHO_ROUTE='hang-trong-khos/createMulti';

module.exports.GET_PHIEUXUATKHO_ROUTE = 'phieu-xuat-khos/';

module.exports.Employee_Position = {
  'employee':0,
  'manager':1,
  'accountant':2,
  'director':3,
}

module.exports.Order_Status = {
  'chờ duyệt':0,
  'đang xử lý':1,
  'đã xử lý':2,
  'bỏ đơn':3
}



module.exports.MakeId= length => {
   var result           = '';
   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}
module.exports.UserRole={
  admin: 'Admin',
  public:'Public',
  authen:'Authenticated'
}

module.exports.ConvertNumberToCurrency = e => {
    var formatter = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'VND' });
    return formatter.format(e);
  }

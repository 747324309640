const dev = {
  apiGateway: {
    // URL: "http://localhost:1337/"
    URL: "http://128.199.205.42:1337/"
  }
};

const staging = {
  apiGateway: {
    URL: "http://128.199.205.42:1337/"
  }
};

const production = {
  apiGateway: {
    URL: "http://128.199.205.42:1337/"
  }
};

const env = process.env.NODE_ENV;
let config = dev;

if (env === 'staging') {
  config = staging;
}

if (env === 'production') {
  config = production;
}

module.exports = config;

import * as actionTypes from './actions'
const initialState = {
  currentUserToken: '',
  currentRole:'',
  currentUserName:'',
  currentEmail:''
}
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER:
      var newstate = {
        ...state,
        currentUserToken: action.payload.jwt,
        currentUserName: action.payload.username,
        currentEmail:action.payload.email,
        currentRole: action.payload.role
      };
      return newstate;
    case actionTypes.SIGNOUT_SUCCESS:
      if(localStorage["user"]){
        localStorage.removeItem("user");
      }
      return {
        ...state,
        currentUserToken: '',
        currentRole:'',
        currentEmail:'',
        currentUserName:''
      };
    case actionTypes.SIGNUP_SUCCESS:
      return{
        ...state

      }
    case actionTypes.SIGNUP_ERROR:
        return{
          ...state
        }
    default:
    return state;
  }
}
export default userReducer;

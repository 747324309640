import * as actionTypes from './actions'

const initialState = {
  nhanviens: []
}
const nhanvienReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EMPlOYEE:
      const newstate = {
        ...state,
        nhanviens: action.payload
      };
      return newstate;
    case actionTypes.DELETE_EMPLOYEE:
      let nstate= state.nhanviens.filter((item)=>{
        return item._id !== action.payload.id
      });
      return {
        ...state,
        nhanviens: nstate
      }
    case actionTypes.UPDATE_EMPLOYEE:
      let nstat= state.nhanviens.map((item)=>{
        if(item._id === action.payload.id){
          item = action.payload.data
        }
        return item;
      });
      return {
        ...state, nhanviens: nstat
      }
    case actionTypes.ADD_EMPLOYEE:
      return {
        ...state,
        nhanviens: state.nhanviens.concat([action.payload.data])
      }
    default:
      return state;
  }
}
export default nhanvienReducer;

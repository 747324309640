import axios from 'axios'
import config from '../config/config'
import {AUTH_ROUTE} from '../utils/constants'

class AuthService {

  login(username, password) {
     axios.post(config.apiGateway.URL + AUTH_ROUTE, {
        username,
        password
      })
      .then(response => {
        let userData ={jwt:response.data.jwt, email: response.data.user.email, role: response.data.user.role.name}
        localStorage.setItem("user", userData);
        return userData;
      }).catch(err=>{
        console.log('login is errored', err);
        return null;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(config.apiGateway.URL + "register", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();

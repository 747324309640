import axios from 'axios'
import config from '../config/config'
import {GET_EMPLOYEE_ROUTE, GET_ORDERS_ROUTE, GET_CATEGORY_ROUTE,
        GET_CUSTOMER_ROUTE, GET_ORDER_DETAIL_ROUTE, GET_PROVIDER_ROUTE} from '../utils/constants'
//define actions in projects
export const ADD_NINJA = 'ADD_NINJA';
export const GET_EMPlOYEE = 'GET_EMPlOYEE';
export const GET_CUSTOMERS ='GET_CUSTOMERS';
export const GET_ORDERS = 'GET_ORDERS';
export const ADD_ORDER='ADD_ORDER';
export const DELETE_ORDER='DELETE_ORDER';
export const UPDATE_ORDER_STATUS='UPDATE_ORDER_STATUS';
export const GET_ORDER_ID='GET_ORDER_ID';
export const UPDATE_ORDER_DETAIL_STATUS='UPDATE_ORDER_DETAIL_STATUS'

export const GET_CATEGORY ='GET_CATEGORY';
export const GET_CATEGORY_ID ='GET_CATEGORY_ID';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const ADD_CATEGORY ='ADD_CATEGORY';

export const GET_COLOR_BY_CATENAME='GET_COLOR_BY_CATENAME';
export const ADD_CUSTOMER ='ADD_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';

export const GET_PROVIDERS ='GET_PROVIDERS';
export const ADD_PROVIDER ='ADD_PROVIDER';
export const DELETE_PROVIDER = 'DELETE_PROVIDER';
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';

export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const ADD_EMPLOYEE ='ADD_EMPLOYEE';

export const GET_USER = 'GET_USER';
export const SIGNOUT_SUCCESS='SIGNOUT_SUCCESS';
export const SIGNUP_SUCCESS='SIGNUP_SUCCESS';
export const SIGNUP_ERROR='SIGNUP_ERROR';

export const getEmployee = () => {
  return (dispatch, getState) => {
    //check nhanvien state change
    if(getState().nhanvien.nhanviens.length===0){
      axios.get(config.apiGateway.URL+GET_EMPLOYEE_ROUTE, {params:{_limit: -1}})
            .then(res=>{
              dispatch({
                type: GET_EMPlOYEE, payload: res.data
              })
            })

    }
  }
}
export const deleteEmployee=(id)=>{
  return (dispatch, getState) => {
    // console.log(config.apiGateway.URL+GET_EMPLOYEE_ROUTE+ id, 'Bearer '+ getState().userToken.currentUserToken);
    axios.delete(config.apiGateway.URL+GET_EMPLOYEE_ROUTE+ id,
                {headers:{'Authorization': 'Bearer '+ getState().userToken.currentUserToken}})
          .then(res=>{
            dispatch({
              type: DELETE_EMPLOYEE, payload: {data: res.data, id}
            })
          })
  }
}
export const updateEmployee=(id, updateData)=>{
  return (dispatch, getState) => {
    //make async call to database
    axios.put(config.apiGateway.URL+GET_EMPLOYEE_ROUTE+ id, {position: updateData.position,
                                                          name: updateData.name,
                                                          social_id: updateData.social_id,
                                                          phone:updateData.phone},
                                                          {headers:{'Authorization': 'Bearer '+ getState().userToken.currentUserToken}})
          .then(res=>{
            dispatch({
              type: UPDATE_EMPLOYEE, payload: {data: res.data, id}
            })
          })

  }
}
export const addEmployee = (employee) =>{
  return (dispatch, getState) =>{
    axios.post(config.apiGateway.URL+GET_EMPLOYEE_ROUTE, {name: employee.name,
                                                        position: employee.position,
                                                        social_id: employee.social_id,
                                                        phone: employee.phone},
              {headers:{'Authorization': 'Bearer '+ getState().userToken.currentUserToken}}
              ).then(res =>{
                dispatch({
                  type: ADD_EMPLOYEE, payload: {data: res.data}
                })
              }
            ).catch(e =>{
              console.log('error from dispatch- addNewEmployee', e)
            })
  }
}
export const deleteCustomer=(id)=>{
  return (dispatch, getState) => {
    //make async call to database
    axios.delete(config.apiGateway.URL+GET_CUSTOMER_ROUTE+ id,
                {headers:{'Authorization': 'Bearer '+ getState().userToken.currentUserToken}})
          .then(res=>{
            dispatch({
              type: DELETE_CUSTOMER, payload: {data: res.data, id}
            })
          })
  }
}
export const updateCustomer=(id, updateData)=>{
  return (dispatch, getState) => {
    //make async call to database
    axios.put(config.apiGateway.URL+GET_CUSTOMER_ROUTE+ id, {address: updateData.address,
                                                          name: updateData.name,
                                                          isGold: updateData.isGold,
                                                          phone:updateData.phone},
                                                          {headers:{'Authorization': 'Bearer '+ getState().userToken.currentUserToken}})
          .then(res=>{
            dispatch({
              type: UPDATE_CUSTOMER, payload: {data: res.data, id}
            })
          })
  }
}
export const addCustomer = (customer) =>{
  return (dispatch, getState) =>{
    axios.post(config.apiGateway.URL+GET_CUSTOMER_ROUTE, {name: customer.name,
                                                        isGold: customer.isGold,
                                                        address: customer.address,
                                                        phone: customer.phone},
              {headers:{'Authorization': 'Bearer '+ getState().userToken.currentUserToken}}
              ).then(res =>{
                dispatch({
                  type: ADD_CUSTOMER, payload: {data: res.data}
                })
              }
            ).catch(e =>{
              console.log('error from dispatch- addNewEmployee', e)
            })
  }
}
export const getCustomers =()=>{
  return (dispatch, getState) =>{
    //make async call to database
    axios.get(config.apiGateway.URL+GET_CUSTOMER_ROUTE, {params:{_limit: -1}})
          .then(res=>{
            dispatch({
              type: GET_CUSTOMERS, payload: res.data
            })
          })
  }
}

export const getOrders=()=>{
  return (dispatch,getState) =>{
    axios.get(config.apiGateway.URL+GET_ORDERS_ROUTE,{params:{_limit: -1}})
          .then(res=>{
            dispatch({
              type: GET_ORDERS, payload: res.data
            })
          })
  }
}
export const getOrderById=(id)=>{
  return (dispatch,getState) =>{
    axios.get(config.apiGateway.URL+GET_ORDERS_ROUTE+id)
          .then(res=>{
            dispatch({
              type: GET_ORDER_ID, payload: res.data
            })
          })
  }
}

export const updateOrderStatus=(id)=>{
  return (dispatch,getState) =>{
    axios.put(config.apiGateway.URL+GET_ORDERS_ROUTE+id, {status: 2},
                                                          {headers:{'Authorization': 'Bearer '+ getState().userToken.currentUserToken}})
          .then(res=>{
            dispatch({
              type: UPDATE_ORDER_STATUS, payload: {data: res.data, status: 2}
            })
          })
  }
}

export const addOrder=()=>{
  return (dispatch,getState) =>{

  }
}
export const deleteOrder=()=>{
  return (dispatch,getState) =>{
  }
}

export const updateOrderDetailStatus= (id, orderId)=>{
  return (dispatch,getState) =>{
    axios.put(config.apiGateway.URL+GET_ORDER_DETAIL_ROUTE+id, {status: true},
                                                          {headers:{'Authorization': 'Bearer '+ getState().userToken.currentUserToken}})
          .then(res=>{
            // console.log('From dispatch-updateOrderDetailStatus',res);
            dispatch({
              type: UPDATE_ORDER_DETAIL_STATUS, payload: {data: res.data, orderId: orderId}
            })
            getOrders();
          })
  }
}

export const getCategory = ()=>{
  return (dispatch,getState) =>{
    axios.get(config.apiGateway.URL+GET_CATEGORY_ROUTE, {params:{_limit: -1}})
          .then(res=>{
            dispatch({
              type: GET_CATEGORY, payload:res.data
            })
          })
  }
}
export const getCategoryById = (id)=>{
  return (dispatch,getState) =>{
    axios.get(config.apiGateway.URL+GET_CATEGORY_ROUTE+id)
          .then(res=>{
            dispatch({
              type: GET_CATEGORY, payload:res.data
            })
          })
  }
}

export const deleteCategory=(id)=>{
  return (dispatch, getState) => {
    //make async call to database
    axios.delete(config.apiGateway.URL+GET_CATEGORY_ROUTE+ id,
                {headers:{'Authorization': 'Bearer '+ getState().userToken.currentUserToken}})
          .then(res=>{
            dispatch({
              type: DELETE_CATEGORY, payload: {data: res.data, id}
            })
          })

  }
}
export const updateCategory=(id, updateData)=>{
  return (dispatch, getState) => {
    //make async call to database
    axios.put(config.apiGateway.URL+GET_CATEGORY_ROUTE+ id, {inventory: updateData.inventory,
                                                          name: updateData.name,
                                                          provider: updateData.provider,
                                                          color:updateData.color},
                                                          {headers:{'Authorization': 'Bearer '+ getState().userToken.currentUserToken}})
          .then(res=>{
            console.log('From dispatch-updateCategory',res);
            dispatch({
              type: UPDATE_CATEGORY, payload: {data: res.data, id}
            })
          })
  }
}
export const addCategory = (category) =>{
  return (dispatch, getState) =>{
    axios.post(config.apiGateway.URL+GET_CATEGORY_ROUTE, {inventory: category.inventory,
                                                          name: category.name,
                                                          provider: category.provider,
                                                          color:category.color},
              {headers:{'Authorization': 'Bearer '+ getState().userToken.currentUserToken}}
              ).then(res =>{
                dispatch({
                  type: ADD_CATEGORY, payload: {data: res.data}
                })
              }
            ).catch(e =>{
              console.log('error from dispatch- addNewCategory', e)
            })
  }
}

export const deleteProvider=(id)=>{
  return (dispatch, getState) => {
    //make async call to database
    axios.delete(config.apiGateway.URL+GET_PROVIDER_ROUTE+ id,
                {headers:{'Authorization': 'Bearer '+ getState().userToken.currentUserToken}})
          .then(res=>{
            dispatch({
              type: DELETE_PROVIDER, payload: {data: res.data, id}
            })
          })
  }
}
export const updateProvider=(id, updateData)=>{
  return (dispatch, getState) => {
    //make async call to database
    axios.put(config.apiGateway.URL+GET_PROVIDER_ROUTE+ id, {address: updateData.address,
                                                          name: updateData.name,
                                                          description: updateData.description,
                                                          phone:updateData.phone},
                                                          {headers:{'Authorization': 'Bearer '+ getState().userToken.currentUserToken}})
          .then(res=>{
            dispatch({
              type: UPDATE_PROVIDER, payload: {data: res.data, id}
            })
          })

  }
}
export const addProvider = (provider) =>{
  return (dispatch, getState) =>{
    axios.post(config.apiGateway.URL+GET_PROVIDER_ROUTE, {name: provider.name,
                                                        description: provider.description,
                                                        address: provider.address,
                                                        phone: provider.phone},
              {headers:{'Authorization': 'Bearer '+ getState().userToken.currentUserToken}}
              ).then(res =>{
                dispatch({
                  type: ADD_PROVIDER, payload: {data: res.data}
                })
              }
            ).catch(e =>{
              console.log('error from dispatch- addProvider', e)
            })
  }
}

export const getProviders =()=>{
  return (dispatch, getState) =>{
    //make async call to database
    axios.get(config.apiGateway.URL+GET_PROVIDER_ROUTE, {params:{_limit: -1}})
          .then(res=>{
            dispatch({
              type: GET_PROVIDERS, payload: res.data
            })
          })

  }
}

// export const getMaLoMax = ()=>{
//   return (dispatch,getState) =>{
//     axios.get(config.apiGateway.URL+GET_HANGTRONGKHO_ROUTE, {params:{_limit: -1}})
//           .then(res=>{
//             dispatch({
//               type: GET_CATEGORY, payload:res.data
//             })
//           })
//   }
// }

export const getUser =(userToken)=>{
  return (dispatch,getState) =>{
    dispatch({
      type: GET_USER, payload:userToken
    })
  }
}



export const signOut = () => {
  return (dispatch, getState) => {
      dispatch({ type: SIGNOUT_SUCCESS
    })
  }
}

export const signUp = () => {
  return (dispatch, getState) => {
      dispatch({ type: SIGNUP_SUCCESS
    })
  }
}

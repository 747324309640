import * as actionTypes from './actions'

const initialState = {
  categorys: [],
  colorByCateName:[]
}
const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CATEGORY:
    
      var newstate = {
        ...state,
        categorys: action.payload
      };
      return newstate;
    case actionTypes.GET_COLOR_BY_CATENAME:
      var newstate1={
        ...state,
        colorByCateName: action.payload
      };
      return newstate1;
    default:
    return state;
  }
}
export default categoryReducer;

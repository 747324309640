import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect} from 'react-router-dom';
import './scss/style.scss';
import AuthService from './service/auth.service';
import {connect} from 'react-redux';
import {getUser} from './store/actions';


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Profile = React.lazy(() => import('./views/pages/profile/profile'));
const Home = React.lazy(() => import('./views/pages/home/Home.js'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const TestPage = React.lazy(() => import('./TestPage'));


class App extends Component {

  componentDidMount() {
     const user = AuthService.getCurrentUser();
     if (user) {
       this.props.onGetUser(user);
     }
   }
  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              {/* <Route exact path="/" name="TestPage" render={props => <TestPage {...props}/>} /> */}
              <Route exact path="/" name="Home" render={props => <Home {...props}/>} />
              {/* <Route path="/" name="Login Page" render={props => <Login {...props}/>} /> */}
              <Route path="/login" name="Login Page" render={props => <Login {...props}/>} />

              <Route path="/profile" name="Profile Page" render={props => <Profile {...props}/>} />
              <Route path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/home" name="Home" render={props => <Home {...props}/>} />
              {/*check is login or not! if not, redirect to login page. If so, forward to nested routes layout*/}
              <Route path="/admin" name="Admin"
                     render={prs =>
                       this.props.user==='' ? (
                         <Redirect
                           to={{
                             pathname: "/login",
                             state: { from: prs.location }
                           }}
                         />
                       ) : (
                         <TheLayout {...prs}/>
                       )
                     }
              />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}
const mapStateToProps = (state) =>{
  return {
    user: state.userToken.currentUserToken
  }
}

const mapDispatchToProps = dispatch=>{
  return{
    onGetUser: (token)=> dispatch(getUser(token))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);

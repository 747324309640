import {combineReducers} from 'redux'

import nvReducer from './nhanvienReducer'
import khReducer from './khachhangReducer'
import ordReducer from './orderReducer'
import categoryReducer from './categoryReducer'
import userReducer from './userReducer'
import changeStateReducer from './changeStateReducer'
import nccReducer from './nhacungcapReducer'


const rootReducer = combineReducers({
  changeState: changeStateReducer,
  nhanvien: nvReducer,
  khachhang: khReducer,
  order: ordReducer,
  category: categoryReducer,
  userToken: userReducer,
  nhacungcap:nccReducer
});

export default rootReducer;

import * as actionTypes from './actions'

const initialState = {
  orders: []
}
const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ORDERS:
      const newstate = {
        ...state,
        orders: action.payload
      };
      return newstate;
    case actionTypes.GET_ORDER_ID:
      var nstate = {
        ...state,
        orders: action.payload
      };
      return nstate;

    case actionTypes.UPDATE_ORDER_STATUS:
      var newOrder = [...state.orders];
      var i=newOrder.findIndex(item=> item.id===action.payload.data.id);
      if(i){
          newOrder[i].status = action.payload.status;
          return {
            ...state,
            orders: newOrder
          }
      }
      return state;
    case actionTypes.UPDATE_ORDER_DETAIL_STATUS:
      var newOrder = [...state.orders];
      var order = newOrder.find(item => item.id===action.payload.orderId);
      if(order){
        var index=order.order_details.findIndex(item=> item.id === action.payload.data.id);
        if(index >= 0){
          order.order_details[index].status = true;
          return {
            ...state,
            orders: newOrder
          }
        }
      }
      return state;
    default:
    return state;
  }
}
export default orderReducer;

import * as actionTypes from './actions'

const initialState = {
  nhacungcaps: []
}
const nhacungcapReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PROVIDERS:
      const newstate = {
        ...state,
        nhacungcaps: action.payload
      };
      return newstate;
    case actionTypes.DELETE_PROVIDER:
      let nstate= state.khachhangs.filter((item)=>{
        return item._id !== action.payload.id
      });
      return {
        ...state,
        nhacungcaps: nstate
      }
    case actionTypes.UPDATE_PROVIDER:
      let nstat= state.nhacungcaps.map((item)=>{
        if(item._id === action.payload.id){
          item = action.payload.data
        }
        return item;
      });
      return {
        ...state, nhacungcaps: nstat
      }
    case actionTypes.ADD_PROVIDER:
      return {
        ...state,
        nhacungcaps: state.nhacungcaps.concat([action.payload.data])
      }
    default:
    return state;
  }
}
export default nhacungcapReducer;

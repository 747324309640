import * as actionTypes from './actions'

const initialState = {
  khachhangs: []
}
const khachhangReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CUSTOMERS:
      const newstate = {
        ...state,
        khachhangs: action.payload
      };
      return newstate;
    case actionTypes.DELETE_CUSTOMER:
      let nstate= state.khachhangs.filter((item)=>{
        return item._id !== action.payload.id
      });
      return {
        ...state,
        khachhangs: nstate
      }
    case actionTypes.UPDATE_CUSTOMER:
      let nstat= state.khachhangs.map((item)=>{
        if(item._id === action.payload.id){
          item = action.payload.data
        }
        return item;
      });
      return {
        ...state, khachhangs: nstat
      }
    case actionTypes.ADD_CUSTOMER:
      return {
        ...state,
        khachhangs: state.khachhangs.concat([action.payload.data])
      }
    default:
    return state;
  }
}
export default khachhangReducer;
